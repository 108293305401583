import styled from "@emotion/styled";

export const EndCallButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: ${({ theme }) => theme.colors.accent["red/main"]};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent["red/dark"]};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.accent["red/main"]};
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const PlayPauseButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
`;
