/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";

interface ButtonProps {
  variant?: "primary" | "secondary";
  children: React.ReactNode;
  onClick?: VoidFunction;
}

const StyledButton = styled.button<ButtonProps>`
  padding: ${({ theme }) => theme.spacing(1)};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ theme, variant }) =>
    variant === "secondary"
      ? theme.colors.accent["green/main"]
      : theme.colors.primary["primary/01"]};
  color: #fff;
  font-size: 16px;
`;

export const Button: React.FC<ButtonProps> = ({
  variant = "primary",
  children,
}) => {
  return <StyledButton variant={variant}>{children}</StyledButton>;
};

export default Button;
