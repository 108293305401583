import styled from "@emotion/styled";
import { theme } from "assets/theme";
import { keyframes } from "@emotion/react";
import { Text } from "components/common";

interface ParticipantProps {
  name: string;
  onClick?: () => void;
}

const waveAnimation = keyframes`
  0% {
    transform: scaleY(0.3);
  }
  50% {
    transform: scaleY(1.2);
  }
  100% {
    transform: scaleY(0.3);
  }
`;

const VisualizerContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
  width: 120px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  top: 12px;
  right: 12px;
`;

interface BarProps {
  delay: number;
}

const Bar = styled.div<BarProps>`
  background-color: ${theme.colors.accent["purple/main"]};
  width: 6px;
  height: 100%;
  border-radius: 16px;
  transform-origin: center center;
  animation: ${waveAnimation} 1.2s infinite ease-in-out;
  animation-delay: ${(props) => props.delay}s;
`;

const ParticipantTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(54, 52, 60, 0.19);
  backdrop-filter: blur(22.200000762939453px);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  transition: border-color 0.2s;
  cursor: pointer;
`;

const ActiveParticipantTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(54, 52, 60, 0.19);
  backdrop-filter: blur(22.200000762939453px);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  transition: border-color 0.2s;
  border-color: ${theme.colors.accent["purple/main"]};
  cursor: default;
`;

const NameTag = styled.div`
  color: white;
  font-size: 18px;
`;

const ActiveNameTag = styled.div`
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  position: absolute;
  bottom: 8px;
  left: 8px;
  font-size: 14px;
`;

const TranscriptTile = styled.div`
  padding: 4px 24px;
`;

export const Participant: React.FC<ParticipantProps> = ({ name, onClick }) => {
  return (
    <ParticipantTile onClick={onClick}>
      <NameTag>{name}</NameTag>
    </ParticipantTile>
  );
};

type ActiveParticipantProps = {
  name: string;
  transcript: string;
};
export const ActiveParticipant: React.FC<ActiveParticipantProps> = ({
  name,
  transcript,
}) => {
  const bars = [0, 0.2, 0.4, 0.6, 0.3, 0.5, 0.7, 0.1, 0.8, 0.9];
  return (
    <ActiveParticipantTile>
      <ActiveNameTag>{name}</ActiveNameTag>
      <VisualizerContainer>
        {bars.map((delay, index) => (
          <Bar key={index} delay={delay} />
        ))}
      </VisualizerContainer>
      <TranscriptTile>
        <Text.Body>{transcript}</Text.Body>
      </TranscriptTile>
    </ActiveParticipantTile>
  );
};
