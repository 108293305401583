import { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { Dialog, Text } from "components";
import { CustomGoogleIcon, MultiplayerLogo } from "components/icons";
import { useQuery } from "@apollo/client";
import { css } from "@emotion/css";
import "./google.css";
import { getAccessToken, removeTokens } from "services";
import { E_Route } from "utils/constants";
import { GOOGLE_OAUTH_QUERY } from "apollo";
import { GoogleOauthQueryDto } from "types";
import { extractFromQuery } from "utils/helpers";
import Texture from "./static/Texture.png";
import { theme } from "assets/theme";
import ReactGA from "react-ga4";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 48px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${Texture});
`;

const LoginButton = styled.button`
  display: flex;
  flex-direction: row;
  max-width: 399px;
  height: 64px;
  max-width: 424px;
  width: 100%;
  border-radius: 76px;
  border: 1.333px solid #2e2d33;
  color: #fff;
  font-size: 18px;
  background: #151419;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  @media (max-width: 1030px) {
    padding: auto;
  }
`;

export const LoginPage = () => {
  const { data } = useQuery<any, GoogleOauthQueryDto>(GOOGLE_OAUTH_QUERY, {
    variables: {
      oauthData: {
        redirect: `${process.env.REACT_APP_HOST_URL}${E_Route.AuthContinue}`,
        type: "login",
        scopes: [
          // "https://www.googleapis.com/auth/calendar.events",
          // "https://www.googleapis.com/auth/calendar.readonly",
          "https://www.googleapis.com/auth/userinfo.email",
          "https://www.googleapis.com/auth/userinfo.profile",
          "https://www.googleapis.com/auth/gmail.modify",
          "https://www.googleapis.com/auth/contacts.readonly",
          "https://www.googleapis.com/auth/contacts.other.readonly",
        ],
      },
    },
  });

  const checkAuth = (authWindow: Window | null) => {
    //const openGmail = () => {
    //  window.open("https://mail.google.com/mail/u/0/#inbox", "_blank");
    //};
    const intervalId = setInterval(() => {
      const token = getAccessToken();
      if (token) {
        clearInterval(intervalId);
        if (authWindow) {
          authWindow.close();
        }
        //openGmail();
        window.location.href = E_Route.Home;
      }
    }, 1000);
  };

  const handleGoogleLogin = useCallback(() => {
    ReactGA.event({
      category: "Login",
      action: `Login clicked`,
      label: "Login",
      nonInteraction: false,
      transport: "xhr",
    });
    if (data?.googleOAuthUrl) {
      removeTokens();
      const url = data?.googleOAuthUrl;
      const authWindow = window.open(url, "_blank");
      checkAuth(authWindow);
    }
  }, [data?.googleOAuthUrl]);

  useEffect(() => {
    const { source } = extractFromQuery<any>(window.location.search);
    if (source && source === "ads") {
      handleGoogleLogin();
    }
  }, [handleGoogleLogin]);

  return (
    <Container>
      <MultiplayerLogo />

      <Text.H1
        color="text/100%"
        style={{
          fontSize: "40px",
          textAlign: "center",
          lineHeight: "48px",
          marginTop: "60px",
          marginBottom: "1px",
        }}
      >
        Achieve <span style={{ color: "#AAEF65" }}>Inbox Zero</span> today
      </Text.H1>
      <Text.H4
        style={{
          color: "#909090",
          fontSize: "16px",
          textAlign: "center",
          fontWeight: 300,
          marginBottom: "40px",
          lineHeight: "25px",
        }}
      >
        The first AI to manage your inbox for you
      </Text.H4>
      <LoginButton onClick={handleGoogleLogin}>
        <CustomGoogleIcon
          style={{
            width: "32px",
            height: "32px",
            marginRight: "19px",
          }}
        />{" "}
        Continue with Google
        <div
          className={css`
            position: absolute;
            bottom: -20px;
            right: 0px;
          `}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="64"
            viewBox="0 0 200 64"
            fill="none"
          >
            <g filter="url(#filter0_f_6159_5448)">
              <ellipse
                cx="156.333"
                cy="104"
                rx="97.3334"
                ry="76"
                fill="#313131"
                fill-opacity="0.5"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_6159_5448"
                x="0.33334"
                y="-30.6667"
                width="312"
                height="269.333"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="29.3333"
                  result="effect1_foregroundBlur_6159_5448"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </LoginButton>

      <div
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 200px;
        `}
      >
        <Dialog
          coverAll
          title="Why you’ll love Multiplayer"
          additionalContent={
            <div>
              <p>
                Multiplayer works like a human executive assistant, triaging
                your existing Gmail inbox for you.
              </p>
              <h3>How it works:</h3>
              <ul>
                <li> Pre-reads your email for you</li>
                <li>
                  Labels every email with one of the following:
                  <ul>
                    <li>@userfirstname</li>
                    <li>calendar</li>
                    <li>events</li>
                    <li>financial/receipts</li>
                    <li>notifications</li>
                    <li>newsletters</li>
                    <li>sales</li>
                    <li>travel</li>
                  </ul>
                </li>
                <li>
                  {" "}
                  Archives emails that don’t absolutely require your attention
                </li>
                <li>
                  {" "}
                  Publishes a daily podcast summarizing less important
                  newsletters and other updates
                </li>
              </ul>
            </div>
          }
        >
          <p
            className={css`
              color: #6a53fe;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              text-decoration: underline;
              margin-right: 44px;
              &:hover {
                text-decoration: underline;
              }
            `}
          >
            Learn more
          </p>
        </Dialog>
        <Dialog
          coverAll
          title="How we secure your data"
          additionalContent={
            <div>
              <h3>What we believe</h3>
              <ul>
                <li>
                  We want a clean conscience when it comes to doing right by our
                  customers. Trust and humanity means everything to us.
                </li>
                <li>
                  We do not sell your data, especially for advertising or
                  marketing purposes. You are the customer, not the “product.”
                </li>
                <li>
                  People have different comfort levels when it comes to using AI
                  with their data, you should have granular control over how
                  much access you give to AI tools.
                </li>
              </ul>

              <h3>Our commitment to your privacy and security</h3>
              <ul>
                <li>
                  <b>Purposeful email management</b>: Our app is designed to
                  streamline your email workflow by categorizing incoming emails
                  into predefined labels and archiving those that don’t require
                  your immediate attention (e.g., newsletters, receipts). All
                  additional features are built around this core capability.
                </li>
                <li>
                  <b>Privacy-first approach</b>: We do not store your email
                  content or use your data for any form of model training.
                  Instead, we employ techniques that deliver personalized
                  experiences without retaining or learning from your email
                  content. Furthermore, we do not utilize any third-party
                  services that would violate this approach.
                </li>
                <li>
                  <b>Minimal data storage</b>: To enhance personalization, we
                  store essential information, such as the email addresses you
                  interact with and publicly available data associated with
                  these individuals (such as job titles, etc). This ensures our
                  app provides contextually rich experiences without
                  overstepping privacy boundaries.
                </li>
                <li>
                  <b>Data access transparency</b>: We access only the data
                  necessary to deliver the app’s features. There is no
                  indiscriminate collection of your data, and no additional data
                  processing occurs beyond what’s needed to provide the services
                  you expect.
                </li>
              </ul>

              <h3>Customer Data Protection</h3>
              <ul>
                <li>
                  Multiplayer use a role based access control system to ensure
                  that only authorized and authenticated users can access any
                  resource
                </li>
                <li>
                  All communications between customers and our server use HTTPS
                  or SSH to encrypt the data in transit.
                </li>
                <li>
                  Only our allowed applications are able to query information
                  from our databases.
                </li>
                <li>
                  All data will be encrypted on the server-side using our cloud
                  provider’s default encryption keys. Data is automatically
                  decrypted when read by an authorized user using KMS-based
                  protections.
                </li>
                <li>
                  Since our only authentication method is through Google OAuth2,
                  there is no need to store any password from the end user in
                  our database, which eliminates the risk of any attacker
                  stealing passwords.
                </li>
                <li>
                  Only allowed domains (currently only multiplayer.work) are
                  allowed to communicate with our servers.
                </li>
              </ul>

              <h3>Read more</h3>
              <ul>
                <li>
                  <a
                    href="https://www.multiplayer.work/terms-of-service"
                    style={{ color: theme.colors.text["text/link"] }}
                  >
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.multiplayer.work/privacy-policy"
                    style={{ color: theme.colors.text["text/link"] }}
                  >
                    Privacy policy
                  </a>
                </li>
              </ul>

              <Text.BodySM color="text/75%">
                Last updated: January 23, 2025
              </Text.BodySM>
            </div>
          }
        >
          <p
            className={css`
              color: #686868;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              text-decoration: underline;
              &:hover {
                text-decoration: underline;
              }
            `}
          >
            How we secure your data
          </p>
        </Dialog>
        {/* <div
                  style={{
                    margin: 'auto',
                    width: 'fit-content',
                  }}
                >
                  <GoogleSignInButton
                    disabled={!data?.googleOAuthUrl}
                    onClick={handleGoogleLogin}
                  />
                </div> */}
      </div>
      <div
        className={css`
          position: absolute;
          top: -10px;
          z-index: 0;
          border: 1px solid red;
          display: none;
        `}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1283"
          height="421"
          viewBox="0 0 1283 821"
          fill="none"
        >
          <g filter="url(#filter0_f_6159_5381)">
            <path
              d="M980 517.648H303.5L521.433 -852H802.93L980 517.648Z"
              fill="#313131"
              fill-opacity="0.45"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_6159_5381"
              x="0.81543"
              y="-1154.68"
              width="1281.87"
              height="1975.02"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="151.342"
                result="effect1_foregroundBlur_6159_5381"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </Container>
  );
};

export default LoginPage;
