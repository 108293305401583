import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "context";
import styled from "@emotion/styled";
import { theme } from "assets/theme";
import { css } from "@emotion/css";
import axios from "axios";
import { Text } from "components/common";

const Input = styled.input`
  outline: none;
  border-radius: 57px;
  border: 1px solid #808080;
  box-sizing: border-box;
  transition: border-color 0.15s;
  width: 100%;
  height: 48px;
  margin-right: 7px;
  background: #1b1a1f;
  color: ${theme.colors.text["text/main"]};
  font-size: 15px;
  font-weight: ${theme.font["body-sm"].fontWeight};
  font-family: ${theme.font["body-sm"].fontFamily};
  padding-right: 12px;
  padding-left: 12px;
  &::placeholder {
    color: #808080;
    font-size: 15px;
  }
  &:hover,
  &:focus {
    border-color: #8d86f8;
  }
`;

const Button = styled.button`
  all: unset;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  height: 48px;
  width: 100%;
  padding-inline: 0px;
  border-radius: 57px;
  border: 1px solid #8d86f8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  color: #8d86f8;
  position: relative;
  overflow: hidden;
`;

const Section = styled.section`
  margin: auto;

  border-radius: 16px;
  border: 1px solid #29282f;

  background: #1a191e;

  backdrop-filter: blur(1.5px);
  padding: 28px;
`;

const ProductDisplay: React.FC = () => {
  const [quantity, setQuantity] = useState<number | undefined>();
  const { state: userState } = useContext(UserContext);
  const [costPerCredit, setCostPerCredit] = useState<number>(0);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/billing/get-credit-cost`)
      .then((res) => {
        setCostPerCredit((res.data || 0) / 100);
      })
      .catch();
  }, []);

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(parseInt(e.target.value));
  };
  return (
    <Section>
      <div>
        <div>
          <h3
            style={{
              textTransform: "uppercase",
              color: "#8D86F8",
              fontSize: "13px",
              marginBottom: "16px",
            }}
          >
            Buy Multiplayer credits
          </h3>
          <h3
            style={{
              textTransform: "uppercase",
              color: "#FFF",
              fontSize: "17px",
              marginTop: "0px",
              marginBottom: "0px",
            }}
          >
            1 credit = ${costPerCredit.toFixed(2)}
          </h3>
          <div
            className={css`
              width: 100%;
              height: 16px;
              margin-bottom: 16px;
              border-bottom: 1px solid #2e2d33;
            `}
          />
        </div>
      </div>
      <form
        action={`${process.env.REACT_APP_API_URL}/billing/create-checkout-session`}
        method="POST"
      >
        <div
          className={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          <Input
            placeholder="Number of credits"
            type="number"
            min="5"
            name="quantity"
            required
            value={quantity}
            onChange={handleQuantityChange}
          />

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="8"
            viewBox="0 0 16 8"
            fill="none"
          >
            <rect width="16" height="3" fill="#53515C" />
            <rect y="5" width="16" height="3" fill="#53515C" />
          </svg>

          <Text.Body style={{ marginLeft: "24px", fontSize: "18px" }}>
            ${(costPerCredit * (quantity || 0)).toFixed(2)}
          </Text.Body>
        </div>
        <input type="hidden" name="userId" value={userState?._id} />
        <Button type="submit">
          Checkout
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="162"
            height="48"
            viewBox="0 0 162 48"
            fill="none"
            className={css`
              position: absolute;
              bottom: -10px;
              right: -10px;
            `}
          >
            <g filter="url(#filter0_f_6158_1042)">
              <ellipse
                cx="97"
                cy="65"
                rx="53"
                ry="41"
                fill="#6A53FE"
                fill-opacity="0.15"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_6158_1042"
                x="0"
                y="-20"
                width="194"
                height="170"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="22"
                  result="effect1_foregroundBlur_6158_1042"
                />
              </filter>
            </defs>
          </svg>
        </Button>
      </form>
    </Section>
  );
};

export const Payment: React.FC = () => {
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      // show success message
    }
  }, []);

  return <ProductDisplay />;
};
