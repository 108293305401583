import React from "react";
import { css } from "@emotion/css";
import { AppButton, PageLayout, Text } from "components";
import { useAuth } from "hooks";
import { StartGroupIcon, UploadSimpleIcon } from "components/icons";
import ReactGA from "react-ga4";

export const HomePage: React.FC = () => {
  useAuth();

  const goToAppleStore = () => {
    ReactGA.event({
      category: "CTA",
      action: `App store btn clicked`,
      label: "CTA",
      nonInteraction: false,
      transport: "xhr",
    });
    window.location.href =
      "https://apps.apple.com/us/app/multiplayer-ai-for-work/id6642706353";
  };

  return (
    <PageLayout title="Home | Multiplayer">
      <div
        className={css`
          max-width: 562px;
          width: 100%;
          border-radius: 29.208px;
          border: 1.825px solid #29282f;

          background: #1a191e;

          backdrop-filter: blur(2.738227128982544px);
          display: flex;
          flex-direction: column;
          margin: auto;
          padding: 50px;
        `}
      >
        <StartGroupIcon style={{ marginBottom: "24px" }} />
        <Text.H1 style={{ fontSize: "48px", marginBottom: "30px" }}>
          You’re all set!
        </Text.H1>
        <Text.Body
          style={{
            fontSize: "21px",
          }}
          marginTop={3.5}
          marginBottom={0}
        >
          Your Multiplayer AI is now active on Gmail.
        </Text.Body>

        <Text.BodySM
          style={{
            color: "#909090",
            fontSize: "19px",
            marginTop: "8px",
            lineHeight: "22px",
          }}
        >
          Your AI is already hard at work in the background. It's clearing out
          spam and unwanted emails to keep your inbox focused on what matters
          most to you. Check out the new smart labels it's already created!
        </Text.BodySM>

        <div
          className={css`
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 46px;
          `}
        >
          <AppButton onClick={goToAppleStore}>
            <UploadSimpleIcon fill="#AAEF65" style={{ marginRight: "12px" }} />
            <Text.Body>Download IOS app</Text.Body>
          </AppButton>
        </div>
      </div>
    </PageLayout>
  );
};
