import { PageLayout } from "components/layout";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { AppButton, Text } from "components/common";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const FeedbackForm = styled.form`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Label = styled.label`
  margin: 10px 0 5px;
  font-weight: 600;
  color: #333;
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  width: 300px;
  height: 100px;
  font-family: inherit;
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: inherit;
  margin-bottom: 20px;
`;

export const MeetingEndedScreen = () => {
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState("5"); // default rating

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // In a real app, send feedback to your backend here.
    console.log("User feedback:", feedback);
    console.log("User rating:", rating);

    // Clear fields after submission (optional)
    setFeedback("");
    setRating("5");

    // close the window
    window.close();
  };

  return (
    <PageLayout title="Meeting | Multiplayer">
      <PageContainer>
        <Text.H2>Meeting Ended</Text.H2>
        <Text.Body>We'd love to hear your feedback!</Text.Body>
        <FeedbackForm onSubmit={handleSubmit}>
          <Label htmlFor="feedback">Please leave your feedback below:</Label>
          <TextArea
            id="feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />

          <Label htmlFor="rating">How was your experience?</Label>
          <Select
            id="rating"
            value={rating}
            onChange={(e) => setRating(e.target.value)}
          >
            <option value="1">1 - Poor</option>
            <option value="2">2</option>
            <option value="3">3 - Average</option>
            <option value="4">4</option>
            <option value="5">5 - Great</option>
          </Select>

          <AppButton
            onClick={() => {
              window.close();
            }}
          >
            Submit Feedback
          </AppButton>
        </FeedbackForm>
      </PageContainer>
    </PageLayout>
  );
};
