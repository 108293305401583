import React from "react";
import ReactDOM from "react-dom/client";
import { Global, ThemeProvider } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "config/validateEnv";
import { globalStyles, theme } from "assets/theme";
import { HelmetProvider } from "react-helmet-async";
import { client } from "apollo";
import { ApolloProvider } from "@apollo/client";
import { UserContextProvider } from "context";
import ReactGA from "react-ga4";
import "./index.css";

// Google analytics
ReactGA.initialize(process.env.REACT_APP_GOOGLE_MEASUREMENT_ID!);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <UserContextProvider>
          <ThemeProvider theme={theme}>
            <HelmetProvider>
              <Global styles={globalStyles} />
              <App />
            </HelmetProvider>
          </ThemeProvider>
        </UserContextProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
