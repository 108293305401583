import { useMutation } from "@apollo/client";
import { css } from "@emotion/css";
import { DEACTIVATE_ACCOUNT } from "apollo";
import { theme } from "assets/theme";
import { AppButton, Dialog, Text } from "components";
import { WarningIcon } from "components/icons";
import { useNavigate } from "react-router-dom";
import { removeTokens } from "services";
import pubsub from "sweet-pubsub";
import { ENotificationKey, E_Route } from "utils/constants";

interface Props {
  open?: boolean;
  onOpenChange?(open: boolean): void;
}
export function DeactivateDialog(props: Props) {
  const [deactivateAccount] = useMutation(DEACTIVATE_ACCOUNT);

  const navigate = useNavigate();

  const handleDeactivateAccount = () => {
    deactivateAccount()
      .then(() => {
        removeTokens();
        window.location.reload();

        navigate(E_Route.AuthBegin);
      })
      .catch();
  };

  const handleCancel = () => {
    pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value: false });
    props.onOpenChange?.(false);
  };

  return (
    <Dialog
      noClose
      additionalContent={
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 360px;
          `}
        >
          <WarningIcon style={{ marginBottom: "28px" }} />
          <Text.H4>Are you absolutely sure?</Text.H4>
          <Text.Body
            style={{
              color: "#909090",
            }}
          >
            This action will deactivate your account. In result, all our
            services will be stopped for you.
          </Text.Body>
          <div
            className={css`
              width: 60%;
              margin-bottom: 24px;
            `}
          />
          <AppButton onClick={handleDeactivateAccount}>Yes, continue</AppButton>
          <div
            className={css`
              width: 100%;
            `}
          >
            <Text.Body
              style={{
                color: theme.colors.text["text/100%"],
                textAlign: "center",
                cursor: "pointer",
                marginTop: "24px",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Text.Body>
          </div>
        </div>
      }
      open={props.open}
      onOpenChange={props.onOpenChange}
    />
  );
}
