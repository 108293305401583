export const colors = {
  accent: {
    "blue/light": "#6E8EFF",
    "blue/main": "#406AFF",
    "blue/dark": "#194BFF",
    "blue/soft": "#8C83C3",

    "cyan/light": "#A5D9EF",
    "cyan/main": "#7EC8E8",
    "cyan/dark": "#5DBAE2",

    "green/light": "#CCF5EF",
    "green/main": "#3CAF88",
    "green/dark": "#2FB388",

    "purple/light": "#322D4E",
    "purple/main": "#6A53FE",
    "purple/dark": "#16102B",

    "pink/light": "#F9B1DA",
    "pink/main": "#F041A6",
    "pink/dark": "#B10E6C",

    "red/light": "#FDD0C5",
    "red/main": "#FA4D35",
    "red/dark": "#F94518",

    "yellow/light": "#FFF5BF",
    "yellow/main": "#FADD1C",
    "yellow/dark": "#FFD707",

    white: "#fff",
    black: "#000",
    grey: "#dedede",
    extra: "#96A5C9",
  },

  // Background primary colors
  primary: {
    "primary/dark": "#151419",
    "primary/inactive": "#485574",
    "primary/01": "#151419",
    "primary/02": "#211F30",
    "primary/03": "#272539",
    "primary/04": "#8D86F8",
    "primary/05": "#8C83C3",
    "primary/06": "#453E69",
    "primary/07": "#E5D260",
    "primary/08": "#8AC773",
    "primary/09": "#DFE0F1",
    "primary/10": "#0E1218",
    "primary/11": "#453E69",
    "primary/12": "#FFFFFF",
  },

  text: {
    "text/main": "#FFFFFF",
    "text/dark": "#171522",
    "text/light": "#838094",
    "text/error": "#F94518",
    "text/body": "#C5C4C8",
    "Text/Secondary": "#838094",
    "text/link": "#8D86F8",
    "text/yellow": "#FFD707",
    "text/pink": "#F041A6",
    "text/blue": "#406AFF",
    "text/blue/soft": "#8C83C3",
    "text/extra": "#96A5C9",
    "text/red": "#F94518",
    "text/green": "#2FB388",
    "text/100%": "rgba(255, 255, 255, 1)",
    "text/80%": "rgba(255, 255, 255, 0.8)",
    "text/75%": "rgba(255, 255, 255, 0.75)",
    "text/50%": "rgba(255, 255, 255, 0.5)",
    "text/40%": "rgba(255, 255, 255, 0.4)",
    "text/20%": "rgba(255, 255, 255, 0.2)",
    "text/10%": "rgba(255, 255, 255, 0.1)",
    "text/5%": "rgba(255, 255, 255, 0.05)",
    "text/menu": "#453E69",
    "purple/main": "#6A53FE",
  },
};

export type Color = typeof colors;
