import { css } from "@emotion/css";
import React, { useEffect, useState } from "react";
import { AppRoutes } from "routes";
import { ENotificationKey } from "utils/constants";
import pubsub from "sweet-pubsub";

function App() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const showCallback = (data: { value: boolean }) => {
      setShow(data.value);
    };
    pubsub.on(ENotificationKey.SHOW_DROPDOWN, showCallback);
    return () => {
      pubsub.off(ENotificationKey.SHOW_DROPDOWN, showCallback);
    };
  }, []);
  return (
    <div
      className={css`
        width: 100%;
        height: 100%;
        background-color: #151419;
      `}
    >
      {show && (
        <div
          className={css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(2px);
            background-color: rgba(0, 0, 0, 0.3);
            inset: 0;
            z-index: 999;
          `}
        />
      )}
      <AppRoutes />
    </div>
  );
}

export default App;
