export const STORAGE_KEY = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
};

export enum E_Route {
  AuthBegin = "/auth/login",
  AuthContinue = "/auth/continue",
  Home = "/",
  Media = "/media",
  Done = "/done",
  ManageCredits = "/manage-credits",
  HowWeUseGmail = "/how-we-manage-your-inbox",
  Meeting = "/meeting",
  MeetingEnded = "/meeting-ended",
}

export enum ENotificationKey {
  SHOW_DROPDOWN = "SHOW_DROPDOWN",
  SHOW_LOGOUT = "SHOW_LOGOUT",
  UPDATE_TASK = "UPDATE_TASK",
  SHOW_TASK_DETAIL = "SHOW_TASK_DETAIL",
  NOTE_UPDATE = "NOTE_UPDATE",
  TODO_UPDATE = "TODO_UPDATE",
}
