import { useEffect, useRef, useState, useCallback } from "react";

/**
 * A custom hook that manages an HTMLAudioElement to play a sequence of tracks.
 */
export function useAudioPlayer() {
  const [tracks, setTracks] = useState<string[]>([]);
  const [currentTrackIndex, setCurrentTrackIndex] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  // Initialize the Audio element once
  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = new Audio();
    }
  }, []);

  // Handle the 'ended' event to move to next track or end sequence
  useEffect(() => {
    const handleEnded = () => {
      setIsPlaying(false);
      if (currentTrackIndex < tracks.length - 1) {
        setCurrentTrackIndex((prevIndex) => prevIndex + 1);
      } else {
        // Last audio in the track has ended
        console.log("Reached the end of the track list.");
      }
    };

    const audioEl = audioRef.current;
    if (audioEl) {
      audioEl.addEventListener("ended", handleEnded);
    }

    return () => {
      if (audioEl) {
        audioEl.removeEventListener("ended", handleEnded);
      }
    };
  }, [currentTrackIndex, tracks.length]);

  const addTrack = useCallback((trackUrl: string) => {
    // track will be unique
    setTracks((prevTracks) => {
      if (prevTracks.includes(trackUrl)) {
        return prevTracks;
      }
      return [...prevTracks, trackUrl];
    });
  }, []);

  const play = useCallback(async () => {
    if (audioRef.current && tracks.length > 0) {
      try {
        await audioRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        console.error("Error playing audio:", error);
      }
    }
  }, [tracks.length]);

  const pause = useCallback(() => {
    audioRef.current?.pause();
    setIsPlaying(false);
  }, []);

  const seekToTime = useCallback((timeInSeconds: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = timeInSeconds;
    }
  }, []);

  const setPlaybackRate = useCallback((rate: number) => {
    if (audioRef.current) {
      audioRef.current.playbackRate = rate;
    }
  }, []);

  const nextTrack = useCallback(() => {
    if (currentTrackIndex < tracks.length - 1) {
      setCurrentTrackIndex((prevIndex) => prevIndex + 1);
    }
  }, [currentTrackIndex, tracks.length]);

  const prevTrack = useCallback(() => {
    if (currentTrackIndex > 0) {
      setCurrentTrackIndex((prevIndex) => prevIndex - 1);
    }
  }, [currentTrackIndex]);

  useEffect(() => {
    if (audioRef.current && tracks.length > 0) {
      audioRef.current.src = tracks[currentTrackIndex];
      audioRef.current.load();
      setTimeout(() => {
        play();
      }, 0);
    }
  }, [tracks, currentTrackIndex, play]);

  const seekToTrackIndex = useCallback(
    (index: number) => {
      if (index < 0 || index >= tracks.length) {
        console.warn("Invalid track index:", index);
        return;
      }
      setCurrentTrackIndex(index);
      //if (shouldPlay) {
      //  // wait for state update
      //  setTimeout(() => {
      //    play();
      //  }, 0);
      //}
    },
    [tracks],
  );

  return {
    tracks,
    currentTrackIndex,
    addTrack,
    play,
    pause,
    seekToTime,
    seekToTrackIndex,
    setPlaybackRate,
    nextTrack,
    prevTrack,
    isPlaying,
  };
}
