import { useLazyQuery } from "@apollo/client";
import { CURRENT_USER } from "apollo";
import { PageLoading } from "components";
import React, { createContext, Dispatch, useEffect, useReducer } from "react";
import { PublicPages } from "routes";
import { E_UserAction, GetMeResponse, User } from "types";

type TUserState = User;

type TUserAction =
  | { type: E_UserAction.SET_USER; payload: TUserState }
  | { type: E_UserAction.CLEAR };

type TContext = {
  state: TUserState;
  dispatch: Dispatch<TUserAction>;
};

const initialState = {} as TUserState;

export const UserContext = createContext({} as TContext);

function userReducer(_state: TUserState, action: TUserAction): TUserState {
  switch (action.type) {
    case E_UserAction.SET_USER:
      return action.payload;
    case E_UserAction.CLEAR:
      return initialState;

    default:
      throw Error("Wrong value");
  }
}

type TUserContextProviderProps = {
  children: React.ReactNode;
};
export const UserContextProvider: React.FC<TUserContextProviderProps> =
  React.memo(({ children }) => {
    const [state, dispatch] = useReducer(userReducer, initialState);
    const [loadUser, { loading }] = useLazyQuery<GetMeResponse>(CURRENT_USER);

    useEffect(() => {
      const publicRoutes = PublicPages.map((r) => r.path.toString());
      const current = window.location.pathname;
      if (!publicRoutes.includes(current)) {
        loadUser({ fetchPolicy: "cache-first" }).then((res) => {
          dispatch({
            type: E_UserAction.SET_USER,
            payload: res.data?.me as TUserState,
          });
        });
      }
    }, [loadUser]);

    return (
      <UserContext.Provider value={{ state, dispatch }}>
        {loading ? <PageLoading /> : children}
      </UserContext.Provider>
    );
  });
