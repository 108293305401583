import { PageLayout } from "components/layout";
import { CallInterface } from "./components";
import axios from "axios";
import { config } from "config";
import { useEffect, useState } from "react";
import { extractFromQuery } from "utils/helpers";
import { Text } from "components/common";

type Params = {
  view?: string;
};

export const PodcastScreen = () => {
  const [participants, setParticipants] = useState<
    {
      name: string;
      src: string;
      transcript: string;
    }[]
  >([]);
  const { view } = extractFromQuery<Params>(window.location.search);

  useEffect(() => {
    const fetchData = async (token: string) => {
      const result = await axios.get(`${config.apiUrl}/podcast/${token}`);
      setParticipants(
        result.data.map((item: any) => ({
          name: item.title,
          src: item.url,
          transcript: item.transcript,
        })),
      );
    };
    if (view) {
      fetchData(view);
    }
  }, [view]);

  if (!view) {
    return <Text.Body>Invalid link</Text.Body>;
  }

  if (!participants.length) {
    return <Text.Body>Loading...</Text.Body>;
  }
  //  const participants = [
  //    {
  //      name: "Overview of your day",
  //      src: "https://storage.googleapis.com/multiplayer-images-public/daily-overview-podcast-6682b798ba35da9fbdd685a6-1736910308686",
  //      transcript: `Hey Djibril, good morning! It’s Wednesday, January 15th, and this is your daily briefing.  Looks like we’ve got a cool and overcast day ahead here in Istanbul, with a chance of sprinkles and a high around 7 degrees Celsius. So, grab a jacket before you head out.
  //
  //Let’s dive into your schedule. You have a pretty packed day today with six meetings lined up, one of which is internal.  Things kick off at 9:30 with the Product Launch Strategy meeting. You’ll be meeting with Alice Johnson, the product manager, and Bob Smith, the marketing director.  I hear they’re both big Harry Potter fans, so maybe a quick chat about the latest wizarding world news could be a nice icebreaker. <pause>
  //
  //Next up at 12:30 is the Quarterly Sales Review with Charlie Brown, the sales manager.  Charlie’s a real foodie, apparently, so perhaps a quick chat about his latest culinary creation could be a good way to connect. …  After lunch, at 2:00, you’ve got the Tech Stack Optimization meeting with David Lee, the CTO, who’s a huge Marvel fan. <pause>
  //
  //At 3:00, it's the Partnership Opportunity meeting with Jane Doe, the CEO of XYZ Corp.  Then, at 4:30, you’ll be diving into Customer Feedback Analysis with John Smith, Head of Customer Success.  Finally, you wrap up the workday with Budget Planning for FY25 at 5:30 with Mary Johnson, the CFO.
  //
  //Later this evening, at 8 PM, you have your internal Eng Team Sync with Nina, Craig, and Nikith.
  //
  //That’s all for today’s briefing, Djibril. Have a productive day!
  //`,
  //    },
  //    {
  //      name: "Email review",
  //      src: "https://storage.googleapis.com/multiplayer-images-public/daily-emails-podcast-6682b798ba35da9fbdd685a6-1736996673979",
  //      transcript: `Hey Djibril, it’s Multiplayer here with your daily email digest.  Hope you’re having a productive day so far, especially there in Istanbul.
  //
  //So, since our last chat, I’ve tidied up your inbox by archiving eight emails.  Nothing urgent or mission-critical, mostly updates and newsletters you can circle back to later.  We’re talking things like your Medium Daily Digest with an article titled "Software Development Is A Beautiful Mess," a TikTok For Business optimization report, and a few promotional emails – one about better meetings from Apollo, another from RB2B on exiting a startup, and one more from Loops about a new contact. There was also a meeting summary from your Eng Team Sync on Zoom, a notification from Loopsbot about a new contact, a CodePen Spark email showcasing the most hearted pens of 2024, and a heads-up from RB2B about your tracking script being offline – might want to peek at that one when you have a moment.
  //
  //For now, nothing pressing demands your immediate attention in your inbox.
  //
  //That’s all for today, Djibril.  Enjoy the rest of your day!
  //`,
  //    },
  //    {
  //      name: "News update",
  //      src: "https://storage.googleapis.com/multiplayer-images-public/daily-news-podcast-6682b798ba35da9fbdd685a6-1736996682406",
  //      transcript: `Hello Djibril, welcome to your daily news briefing. Let’s dive into what’s happening today…
  //
  //First up, some news that might be particularly interesting to you as a Lead Software Engineer at Multiplayer. According to your Medium Daily Digest, there's a piece titled "Software Development Is A Beautiful Mess" by David Rodenas PhD.  It seems like a relevant read, given your profession.  There are several other articles in the digest too, ranging from AI and its impact on inequality, by Ignacio de Gregorio, to a piece on the future of money, not money itself, by Liv Mocanu.  It's a pretty diverse mix, so something might catch your eye.
  //
  //Speaking of interesting reads, you also received an email from Adam Robinson at RB2B Insiders about exiting a startup.  It seems like a valuable resource, especially the tips on focusing on cash flow over profitability and strategically building relationships with potential acquirers and investors. The email highlights a key takeaway: making the sale *their* idea to get *your* price.  Intriguing…  There’s a link to a YouTube replay if you want to delve deeper.
  //
  //And finally, for a bit of lighter fare, CodePen sent their "Spark" email highlighting the most hearted Pens of 2024.  They showcase some impressive work, including a CSS-only 3D image carousel by Niklas Knaack, a services section with cool grid hover effects by kristen17, and a gallery of single-keyframe animations by David East.  It’s always inspiring to see what other creatives are building.
  //
  //That wraps up today’s briefing.  Have a productive day, Djibril.
  //`,
  //    },
  //    {
  //      name: "Events and invitations",
  //      src: "https://storage.googleapis.com/multiplayer-images-public/daily-events-podcast-6682b798ba35da9fbdd685a6-1736996701657",
  //      transcript: `Hey Djibril, it's Multiplayer, your virtual assistant, here with your daily dose of upcoming events and activities. Let's dive into what's happening in the tech world that might pique your interest, given your role at Multiplayer and your location in Istanbul.
  //
  //First off, there's a webinar happening next week that you, as a Lead Software Engineer, might find quite useful. It's called "Developer Productivity with Apache Maven: 18 hacks to accelerate your builds and tests," hosted by Gradle.  It's presented by Brian Demers, a Java Champion and long-time Maven contributor.  The webinar covers topics from basic tips and tricks to more advanced strategies like leveraging observability to manage dependency issues. It's scheduled for January 23rd, from 5 PM to 6 PM, your local time.  Sounds like a good opportunity to pick up some new productivity hacks.
  //
  //Now, let me quickly summarize a few other upcoming tech events. There's a technical session on "Fine-tuning Large Language Models" by Together AI, and a couple of interesting webinars from Apollo GraphQL: one on layered API architecture and another on orchestrating OData APIs with Apollo Connectors. Although these might be relevant to your work, they have already taken place, so you could check if recordings are available if you're interested.
  //
  //For a bit of weekend fun, why not explore some of Istanbul's vibrant cultural scene? A Bosphorus cruise is always a fantastic way to see the city from a different perspective, with stunning views of palaces, mosques, and the iconic bridges. Or, if you're in the mood for something more active, there are several escape games and scavenger hunts around the city that could be a fun way to discover hidden gems.  And, of course, no trip to Istanbul is complete without a visit to the Hagia Sophia – just be prepared for the crowds.  Enjoy your weekend, Djibril!
  //`,
  //    },
  //  ];
  return (
    <PageLayout title="Meeting | Multiplayer">
      <CallInterface title="Daily briefing" participants={participants} />
    </PageLayout>
  );
};
