import styled from "@emotion/styled";
import React, { useContext } from "react";
import { CreditBalance } from "./Balance";
import { Payment } from "./payment";
import { UserContext } from "context";
import { Text } from "components/common";

const Div = styled.div`
  max-width: 550px;
  margin: auto;
`;
export const ManageCredit: React.FC = () => {
  const { state: userState } = useContext(UserContext);
  return (
    <Div>
      <Text.H2
        marginBottom={0}
        style={{ textAlign: "center", fontSize: "23px", marginBottom: "0px" }}
      >
        Manage your Multiplayer credits
      </Text.H2>
      <Text.H4
        marginTop={0}
        style={{
          textAlign: "center",
          color: "#A1A1A1",
          fontSize: "14px",
          marginTop: "0px",
        }}
      >
        Check your balance and get more credits.
      </Text.H4>
      <CreditBalance balance={userState?.creditBalance || 0} />
      <Payment />
    </Div>
  );
};
