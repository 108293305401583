import React from "react";
import { Text } from "components/common";
import { css } from "@emotion/css";

type Props = {
  balance: number;
};
export const CreditBalance: React.FC<Props> = ({ balance }) => {
  return (
    <div
      className={css`
        height: 103px;
        border-radius: 16px;
        border: 1px solid #29282f;

        background: #1a191e;

        backdrop-filter: blur(1.5px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 8px;
        margin-top: 16px;
        padding: 28px;
      `}
    >
      <Text.H4
        style={{
          color: "#AAEF65",
          fontSize: "13px",
          textTransform: "uppercase",
          marginBottom: "6px",
        }}
      >
        Current credit balance{" "}
      </Text.H4>
      <Text.Body
        color="text/100%"
        style={{ marginTop: "0px", fontSize: "18px" }}
      >
        {balance}
      </Text.Body>
    </div>
  );
};
