import styled from "@emotion/styled";
import { Text, useAudioPlayer } from "components/common";
import { useEffect } from "react";
import { EndCallButton, PlayPauseButton } from "./button";
import { ActiveParticipant, Participant } from "./Participant";
import { useNavigate } from "react-router-dom";
import { E_Route } from "utils/constants";

//const CallContainer = styled.div`
//  display: flex;
//  flex-direction: column;
//  height: 100vh;
//`;
const CallContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const TopGrid = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  padding: 10px;
  max-height: 30%;
`;

const LargeActiveContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;
`;

const ControlBar = styled.div`
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  title: string;
  participants: { name: string; src: string; transcript: string }[];
};
export const CallInterface: React.FC<Props> = ({ title, participants }) => {
  const navigate = useNavigate();
  const {
    addTrack,
    play,
    pause,
    seekToTrackIndex,
    currentTrackIndex,
    isPlaying,
  } = useAudioPlayer();

  useEffect(() => {
    participants.forEach((participant) => {
      addTrack(participant.src);
    });
  }, [addTrack, participants]);

  const handleSelectTrack = (name: string) => {
    const index = participants.findIndex((p) => p.name === name);
    seekToTrackIndex(index);
  };

  return (
    <CallContainer>
      <Text.H2 style={{ textAlign: "center" }}>{title}</Text.H2>
      <TopGrid>
        {participants
          .filter((_p, index) => index !== currentTrackIndex)
          .map(({ name }) => (
            <Participant
              key={name}
              name={name}
              onClick={() => handleSelectTrack(name)}
            />
          ))}
      </TopGrid>
      <LargeActiveContainer>
        <ActiveParticipant
          name={participants[currentTrackIndex].name}
          transcript={participants[currentTrackIndex].transcript}
        />
      </LargeActiveContainer>
      <ControlBar>
        <PlayPauseButton onClick={isPlaying ? pause : play}>
          {isPlaying ? "Pause" : "Play"}
        </PlayPauseButton>
        <EndCallButton
          onClick={() => {
            pause();
            navigate(E_Route.MeetingEnded);
          }}
        >
          Leave
        </EndCallButton>
      </ControlBar>
    </CallContainer>
  );
};

export default CallInterface;
