import { css } from "@emotion/css";
import { theme } from "assets/theme";
import { AppButton, Dialog, Text } from "components";
import pubsub from "sweet-pubsub";
import { GameOverIllustration1, GameOverIllustration2 } from "components/icons";
import React from "react";
import { removeTokens } from "services";
import { ENotificationKey } from "utils/constants";

interface Props {
  open?: boolean;
  onOpenChange?(open: boolean): void;
}
export function LogoutDialog(props: Props) {
  const randomValue = Math.floor(Math.random() * 2);
  const handleLogout = () => {
    removeTokens();
    window.location.reload();
  };
  return (
    <Dialog
      noClose
      additionalContent={
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          {randomValue < 1 ? (
            <GameOverIllustration1 />
          ) : (
            <GameOverIllustration2 style={{ marginBottom: "16px" }} />
          )}
          <div
            className={css`
              width: 60%;
              margin-bottom: 24px;
            `}
          >
            <Text.Body
              style={{
                color: "#909090",
                textAlign: "center",
              }}
            >
              You are about to log out of Multiplayer. Are you sure?
            </Text.Body>
          </div>
          <AppButton onClick={handleLogout}>Quit</AppButton>
          <Text.Body
            style={{
              color: theme.colors.text["text/100%"],
              textAlign: "center",
              opacity: 0.5,
              cursor: "pointer",
              marginTop: "24px",
            }}
            onClick={() => {
              pubsub.emit(ENotificationKey.SHOW_DROPDOWN, { value: false });

              props.onOpenChange?.(false);
            }}
          >
            Play Again
          </Text.Body>
        </div>
      }
      open={props.open}
      onOpenChange={props.onOpenChange}
    />
  );
}
