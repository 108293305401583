import { css } from "@emotion/css";
import { theme } from "assets/theme";
import React from "react";

type Props = {
  onClick?: VoidFunction;
  children: React.ReactNode;
};

export const AppButton: React.FC<Props> = ({ children, onClick }) => {
  return (
    <button
      className={css`
        width: 360px;
        height: 46px;
        border-radius: 57px;
        border: 1px solid #2e2d33;

        background: #17161c;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${theme.colors.text["text/100%"]};
        cursor: pointer;
        position: relative;
      `}
      onClick={onClick}
    >
      {children}
      <div
        className={css`
          position: absolute;
          bottom: -3px;
        `}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="284"
          height="49"
          viewBox="0 0 284 49"
          fill="none"
        >
          <g filter="url(#filter0_f_6159_5806)">
            <ellipse
              cx="141.869"
              cy="80.1577"
              rx="110.332"
              ry="47.2246"
              transform="rotate(-0.0372624 141.869 80.1577)"
              fill="#313131"
              fill-opacity="0.5"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_6159_5806"
              x="-0.463135"
              y="0.933105"
              width="284.664"
              height="158.449"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="16"
                result="effect1_foregroundBlur_6159_5806"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </button>
  );
};
