import { css } from "@emotion/css";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { theme } from "assets/theme";

const max = 20;

const colors = ["#6A53FE", "#8D86F8", "#C7C4F2"];

type Props = {
  duration?: number;
  onLoad?: VoidFunction;
};
export const LoadingBar: React.FC<Props> = ({ duration = 3000, onLoad }) => {
  const [background, setBackground] = useState<string[]>(
    _.range(max).map(() => theme.colors.primary["primary/03"]),
  );

  useEffect(() => {
    background.forEach((_item, index) => {
      setTimeout(
        () => {
          setBackground((prev) => {
            const newBackground = [...prev];
            const idx = index % colors.length;
            newBackground[index] = colors[idx];
            return newBackground;
          });
          if (index === max - 1) {
            onLoad?.();
          }
        },
        (duration / max) * index,
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={css`
        width: 100%;
        max-width: 549px;

        height: 24px;
        /* border: 1px solid red; */
        margin-top: 32px;
        display: grid;
        grid-template-columns: repeat(${max}, 1fr);
        gap: 16px;
      `}
    >
      {_.range(max).map((item) => (
        <div
          key={item}
          className={css`
            width: 8px;
            height: 100%;
            background-color: ${background[item]};
            border-radius: 8px;
          `}
        />
      ))}
    </div>
  );
};
