import React, { useContext, useEffect } from "react";
import { MultiplayerLogo } from "components/icons";
import styled from "@emotion/styled";
import { LoadingBar, Text } from "components";
import { useNavigate } from "react-router-dom";
import { extractFromQuery } from "utils/helpers";
import { UserContext } from "context";
import * as crypto from "crypto-js";
import { E_UserAction, User } from "types";
import { saveTokens } from "services";
import config from "config/config";
import { css } from "@emotion/css";
import { useLazyQuery } from "@apollo/client";
import { CURRENT_USER } from "apollo";
import { E_Route } from "utils/constants";
import Texture from "./static/Texture.png";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 48px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${Texture});
`;

type Params = {
  data: string;
  token?: string;
};

export const ContinuePage = React.memo(() => {
  const navigate = useNavigate();
  const { dispatch } = useContext(UserContext);
  const [fetchUser] = useLazyQuery<{ me: User }>(CURRENT_USER);

  useEffect(() => {
    const { data, token } = extractFromQuery<Params>(window.location.search);
    if (data) {
      const decryptedData = crypto.AES.decrypt(data, config.secretKey).toString(
        crypto.enc.Utf8,
      );
      const userData = JSON.parse(decryptedData);
      if (userData?.token?.session) {
        saveTokens(userData?.token?.session, userData.token.refresh);
        dispatch({ type: E_UserAction.SET_USER, payload: userData.user });
        setTimeout(() => {
          window.close();
        }, 2000);
      }
    } else if (token) {
      saveTokens(token, "-");
      fetchUser().then((res) => {
        if (res.data?.me?._id) {
          dispatch({ type: E_UserAction.SET_USER, payload: res.data.me });
          setTimeout(() => {
            navigate(E_Route.ManageCredits);
          }, 1000);
        }
      });
    }
  }, [dispatch, navigate, fetchUser]);

  return (
    <Container>
      <MultiplayerLogo
        style={{ height: "40px", marginBottom: "80px", marginTop: "-30px" }}
      />
      <LoadingBar duration={2500} />
      <Text.Body
        style={{ marginTop: "32px" }}
        className={css`
          @media (max-width: 1030px) {
            font-size: 30px !important;
          }
        `}
      >
        Setup completed
      </Text.Body>
      <Text.BodySM
        style={{ color: "#909090" }}
        className={css`
          @media (max-width: 1030px) {
            font-size: 18px !important;
          }
        `}
      >
        Loading your world
      </Text.BodySM>
      <div
        className={css`
          position: absolute;
          top: -10px;
          z-index: 0;
        `}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1283"
          height="421"
          viewBox="0 0 1283 821"
          fill="none"
        >
          <g filter="url(#filter0_f_6159_5381)">
            <path
              d="M980 517.648H303.5L521.433 -852H802.93L980 517.648Z"
              fill="#313131"
              fill-opacity="0.45"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_6159_5381"
              x="0.81543"
              y="-1154.68"
              width="1281.87"
              height="1975.02"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="151.342"
                result="effect1_foregroundBlur_6159_5381"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </Container>
  );
});
